var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","id":"barra_herramientas"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"mt-4",attrs:{"color":"indigo darken-4","dark":"","elevation":"5","rounded":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","lg":"6"}},[_c('div',{staticClass:"headline font-weight-light mt-1 text-center",domProps:{"textContent":_vm._s(
                _vm.$store.state.dataUser.dataUser.rango == 'Empleado'
                  ? _vm.$route.params.id + 's'
                  : _vm.titulo
              )}})]),_c('v-spacer'),_c('v-col',{staticClass:"mr-3",attrs:{"cols":"4","md":"4","sm":"4","lg":"4"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mb-1",attrs:{"small":"","fab":"","text":""},on:{"click":function($event){_vm.page > 1 ? _vm.$emit('pagina_anterior') : null}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mb-1 ml-4",attrs:{"small":"","fab":"","text":"","color":"white"},on:{"click":function($event){_vm.page <= _vm.todosLosFolder.length / 5
                      ? _vm.$emit('pagina_siguiente')
                      : null}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"","rounded":"","width":"","height":"600","id":"documentos_recibidos"}},[_c('v-container',{staticClass:"fill-heigth",attrs:{"fluid":"","id":"pag_folders"}},[_c('v-sheet',{attrs:{"height":"","width":"","rounded":"","id":"bandeja_entrada","elevation":"0"}},[_c('v-row',[(_vm.filterFolders.length > 0)?_c('v-col',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.load_linear),expression:"$store.state.load_linear"}],attrs:{"color":"red","indeterminate":_vm.$store.state.load_linear,"reverse":""}}),_c('v-list',{attrs:{"elevation":"0","rounded":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-subheader',{staticClass:"header font-weight-ligth ml-5",domProps:{"textContent":_vm._s(_vm.filterFolders.length > 0 ? 'Casos' : '')}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-subheader',{staticClass:"header font-weight-ligth ml-5",domProps:{"textContent":_vm._s(
                          _vm.todosLosFolder.length > 0
                            ? _vm.page +
                              '/' +
                              Math.ceil(_vm.todosLosFolder.length / 5)
                            : '1/1'
                        )}})],1)],1),_c('div'),_c('v-list-item-group',{attrs:{"active-class":"black--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.filterFolders),function(folder){return _c('v-list-item',{key:folder.id,on:{"click":function($event){return _vm.$emit('ver_documento', folder)}}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":"","color":"yellow"}},[_vm._v("mdi-folder")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(folder.title)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(folder.subtitle))]),_c('v-list-item-subtitle',[_vm._v("Tipo documental: "+_vm._s(folder.tipoDocumental))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","fab":"","text":""}},[_c('v-icon',{attrs:{"color":"blue darken-4"}},[_vm._v("mdi-information")])],1)],1)],1)}),1)],1)],1):_c('v-col',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"header font-weight-light text-center",domProps:{"textContent":_vm._s('Bandeja Vacia')}})])],1),_c('v-row',[_c('v-col',[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require('../assets/documentos.png'),"max-width":"500"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid style="" class="">
    <v-row align="center" justify="center" id="barra_herramientas">
      <v-container fluid>
        <v-sheet
          color="indigo darken-4"
          dark
          elevation="5"
          rounded
          class="mt-4"
        >
          <v-row justify="start">
            <v-col cols="6" md="6" sm="6" lg="6">
              <div
                v-text="
                  $store.state.dataUser.dataUser.rango == 'Empleado'
                    ? $route.params.id + 's'
                    : titulo
                "
                class="headline font-weight-light mt-1 text-center"
              ></div>
              
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" md="4" sm="4" lg="4" class="mr-3">
              <v-row align="center" justify="center">
                <v-col cols="1">
                  <v-btn
                    small
                    fab
                    text
                    
                    class="mb-1"
                    @click="page > 1 ? $emit('pagina_anterior') : null"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="1">
                  <v-btn
                    small
                    fab
                    text
                    color="white"
                    class="mb-1 ml-4"
                    @click="
                      page <= todosLosFolder.length / 5
                        ? $emit('pagina_siguiente')
                        : null
                    "
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet
          class=""
         
          elevation=""
          rounded
          width
          height="600"
          id="documentos_recibidos"
        >
          <v-container fluid class="fill-heigth" id="pag_folders">
            <v-sheet height width rounded id="bandeja_entrada" elevation="0">
              <v-row>
                <v-col v-if="filterFolders.length > 0">
                  <v-progress-linear
                    color="red"
                    :indeterminate="$store.state.load_linear"
                    v-show="$store.state.load_linear"
                    reverse
                  >
                  </v-progress-linear>
                  <v-list
                    elevation="0"
                    rounded
                 
                    v-model="selected"
                  >
                    <v-row>
                      <v-col cols="2">
                        <v-subheader
                          class="header font-weight-ligth ml-5"
                          v-text="filterFolders.length > 0 ? 'Casos' : ''"
                        ></v-subheader>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="2">
                        <v-subheader
                          class="header font-weight-ligth ml-5"
                          v-text="
                            todosLosFolder.length > 0
                              ? page +
                                '/' +
                                Math.ceil(todosLosFolder.length / 5)
                              : '1/1'
                          "
                        ></v-subheader>
                      </v-col>
                    </v-row>

                    <div></div>
                    <v-list-item-group
                      v-model="selected"
                      active-class="black--text"
                    >
                      <v-list-item
                        v-for="folder in filterFolders"
                        :key="folder.id"
                        @click="$emit('ver_documento', folder)"
                      >
                        <v-list-item-avatar>
                          <v-icon class="grey lighten-1" dark color="yellow"
                            >mdi-folder</v-icon
                          >
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="folder.title"
                          ></v-list-item-title>

                          <v-list-item-subtitle>{{
                            folder.subtitle
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle
                            >Tipo documental:
                            {{ folder.tipoDocumental }}</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <!-- <v-list-item-action
                          v-if="$store.state.es_rechazado == true"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab smal text v-bind="attrs" v-on="on" @click="$emit('reasignar_caso')">
                                <v-icon color="blue darken-4"
                                  >mdi-undo-variant</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Reasignar Caso</span>
                          </v-tooltip>
                        </v-list-item-action> -->
                        <v-list-item-action>
                          <v-btn small fab text>
                            <v-icon color="blue darken-4"
                              >mdi-information</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col v-else>
                  <v-row>
                    <v-col>
                      <div
                        v-text="'Bandeja Vacia'"
                        class="header font-weight-light text-center"
                      ></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="require('../assets/documentos.png')"
                        max-width="500"
                        class="mx-auto"
                      >
                      </v-img>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

//const axios = require("axios").default;
export default {
  props: ["todosLosFolder", "folderPorPagina", "folders", "tokens", "page"],
  data: () => ({
    rechazo: false,
    infoFiltro: {
      title: "",
      subtitle: "",
      remitente: "",
      tipoDocumental: "",
    },

    objFiltro: [],
    icon: "",
    label: "",
    type: "",

    filtroSelect: "",
    clearFilter: true,
    menu: false,

    selected: [0],
    //folders: [],
    //tokens: [],
    buscarInput: false,
    //folderPorPagina: [],
    //page: 1,
    //todosLosFolder: []
  }),
  created() {
    //this.todosLosFolder.splice(0, this.todosLosFolder.length);
    //this.iniciar();
  },
  computed: {
    //EDUARD
    ...mapState("dataUser", ["foldersUser"]),

    titulo(){
      console.log(this.$route.params.id)
      console.log(atob(this.$route.params.id))
      let titulo 
      switch (this.$route.name) {
        case 'CasosAsignados':

          titulo = 'Casos asignados: '+ atob(this.$route.params.id)+'s'
          break;

        case 'CasosDelegados':
          
           titulo = 'Casos delegados: '+ atob(this.$route.params.id)+'s'
          break;

        case 'CasosRespondidos':

          titulo = 'Casos respondidos: '+ atob(this.$route.params.id)+'s'
          
          break;     
        default:
          break;
      }
      console.log(titulo)
       return titulo
    },

    filterFolders() {
      //EDUARD
      //ESTE FILTRO FUNCIONA
      var filtroTitulo = this.infoFiltro.title,
        filtroRemitente = this.infoFiltro.remitente,
        filtroFecha = this.infoFiltro.subtitle,
        filtroTipoDocumental = this.infoFiltro.tipoDocumental;

      if (
        !filtroTitulo &&
        !filtroRemitente &&
        !filtroFecha &&
        !filtroTipoDocumental
      )
        return this.folders;

      var folders = this.todosLosFolder;

      return folders.filter((folder) => {
        return (
          folder.title.includes(filtroTitulo) &&
          folder.subtitle.includes(filtroFecha) &&
          folder.remitente.includes(filtroRemitente) &&
          folder.tipoDocumental.includes(filtroTipoDocumental)
        );
      });
    },
  },
  methods: {
    filtro() {
      switch (this.filtroSelect) {
        case "Titulo":
          this.label = "Titulo";
          this.type = "text";
          this.icon = "mdi-file-document";
          break;
        case "Fecha":
          this.label = "Fecha";
          this.type = "date";
          this.icon = "mdi-calendar";

          break;
        case "Remitente":
          this.label = "Remitente";
          this.type = "text";
          this.icon = "mdi-account";
          break;
        case "Tipo Documental":
          this.label = "Tipo Documental";
          this.type = "text";
          this.icon = "mdi-book";
          break;
      }
    },
    //EDUARD
    activeFilter() {
      this.menu = false;
      this.clearFilter = false;
    },
    //EDUARD
    desactiveFilter() {
      this.clearFilter = true;
      this.infoFiltro.title = "";
      this.infoFiltro.subtitle = "";
      this.infoFiltro.remitente = "";
      this.infoFiltro.tipoDocumental = "";
    },
  },
};
</script>
var bandeja_entrada = {
    data(){
        return {
            tokens: [],
            folders: [],
            folderPorPagina: [],
            todosLosFolder: [],
            page: 1,
        }
    },
    watch: {
        page: function() {
          //console.log(this.page);
          this.folders = this.folderPorPagina[this.page - 1];
          //console.log(this.folders);
        }
      },

}
export default bandeja_entrada